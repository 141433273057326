/* eslint-disable react/prop-types */
import { TWpGlobal } from "@@types/wordPress";
import Image from "next/image";
import Link from "next/link";
import style from "./blockReferral.module.scss";

type ChatBotReferralsProps = {
  data: TWpGlobal;
};

const ChatBotReferrals: React.FC<ChatBotReferralsProps> = ({ data }) => {
  const dataReferrals = data.acf.chat_bot_referrals;

  return (
    <>
      {dataReferrals.display && (
        <div className={style.referralBox} style={{ backgroundColor: `${dataReferrals.background}` }}>
          <Link href={dataReferrals.link} target="_blank">
            <Image src={dataReferrals.image} width={90} height={90} alt="referral" />
            <div className={style.infoReferral}>
              <div>
                <p>{dataReferrals.title}</p>
                <small> {dataReferrals.text}</small>
              </div>
              <i className="las la-angle-right"></i>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default ChatBotReferrals;
